#signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 100%;
    width: 90%;
    overflow-y: auto;
}

#popup-sheet {
    max-width: 50vw;
    max-height: 50vh;
    overflow: auto;
}

/* @media (max-height: 700px) {
    #signup-form {
        padding-top: 50px;
    }
}

@media (max-height: 600px) {
    #signup-form {
        padding-top: 150px;
    }
}

@media (max-height: 500px) {
    #signup-form {
        padding-top: 250px;
    }
} */