@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&family=Nunito:wght@400;600&family=Poppins:wght@400;600&family=Questrial&family=Quicksand:wght@400;600&family=Raleway:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=Questrial&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Questrial&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#top-bar {
  flex: 1;
  display: flex;
  height: 5vh;
  width: 100vw;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 2px solid var(--light-gray);
}

#top-bar-system-banner {
  flex: 1;
  display: flex;
  height: 4vh;
  width: 100vw;
  padding-bottom: 17px;
  padding-top: 2px;
  border-bottom: 2px solid var(--light-gray);
}

#top-header {
  background-color: white;
}

#row {
  display: flex;
}

.nav-logo {
  height: 4vh;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 10px;
}

button {
  margin-top: 0;
  width: 100%;
  display: inline-block;
  text-align: start;
  border-radius: 8px;
  padding: 14px 100px 14px 15px;
  font-size: 12pt;
  background-color: white;
  cursor: pointer;
}

#help-bar {
  height: 2.5vh;
  display: flex;
  background-color: var(--light-blue-button-hover);
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: center;
}

#help-text {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 10pt;
}

button:hover {
  opacity: 0.7;
}

#layout {
  display: flex;
  width: 100%;
  height: 100%;
}

#main {
  height: fit-content;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

#main-no-auth {
  height: fit-content;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  flex: 1;
}

p {
  font-size: 1rem;
}

ul {
  font-size: 1rem;
}

root {
  --main-blue: #5774f0;
  --light-gray: #f1f3f4;
  --light-green: #d3ffecff;
  --light-green-hover: #f0fff9ff;
  --sign-up-outline: #36498a;
  --dark-blue-button: #2a3041ff;
  --light-blue-button: #cbd1ecff;
  --dark-blue-button-hover: #5d6270ff;
  --light-blue-button-hover: #f3f5fcff;
  --main-blue-hover: #7b92f5ff;
  --delete-consultation-hover: #f4e8ffff;
  --main-red: #E84258;
  --light-red: #ffb8b8;
  --main-orange: #FD8060;
  --light-orange: #fcc092;
  --main-yellow: #FEE191;
  --light-yellow: #fcf992;
  --main-green: #B0D8A4;
  --light-green: #a9fcaf;
}