
#nav-bar {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    width: 12vw;
    min-height: 96.5vh;
    border-right: 2px solid var(--light-gray);
}

#nav {
    display: flex;
    background-color: white;
}

.nav-item {
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    text-decoration: none;
    font-size: 20px;
    color: #000000;
    display: block;
}

.nav-item:hover { 
    opacity: 0.7;
}

.nav-item.active {
    font-weight: 700;
    background: linear-gradient(to right, var(--main-blue) 5%, transparent 5%);
}

#help-section {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-grow: 1;
}

#background-color {
    padding: 20px;
    padding-left: 30px;
    color: black;
    display: flex;
    flex-direction: column;
}

.auto-start {
    padding-left: 30px;
    margin-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    padding-top: 15px;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid #36498a;
    background-color: #f7f9feff;
    border-radius: 50px;
    color: black;
    display: block;
}

.auto-start:hover { 
    opacity: 0.7;
}

.row {
    display: flex;
}

#auto-start-container {
    align-items: center;
    font-size: 16px;
    justify-content: space-between;
}

.system-banner-degraded {
    width: 100%;
    height: 30px;
    background-color: orange;
    color: white;
    text-align: center;
}

.system-banner-operational {
    width: 100%;
    height: 30px;
    background-color: var(--light-green);
    color: white;
    text-align: center;
}


@media (max-width: 1300px) { 
    .nav-item {
        font-size: 18px;
        padding-left: 30px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #background-color {
        padding: 10px;
        padding-left: 15px;
    }
}


.loading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px; /* Space between dots */
  }
  
  .dot {
    width: 8px; /* Circle size */
    height: 8px;
    background-color: var(--main-blue); /* Circle color */
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s; /* Delay for second dot */
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s; /* Delay for third dot */
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(1); /* Default size */
    }
    40% {
      transform: scale(1.5); /* Enlarge for the bounce effect */
    }
  }
  