

.account-page {
    margin-top: 25px;
    margin-left: 50px;
    overflow-y: auto;
    height: 95vh;
}

#welcome-header {
    margin-left: 0px;
}

#welcome-header-settings {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    height: 9vh;
}

.note-settings {
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    height: min-content;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    align-self: center;
    width: 23.1vw;
    margin-right: 2px;
}

#save-settings {
    width: 15vw;
    color: white;
    background-color: var(--main-blue);
    border: 2px solid var(--main-blue);
}

#upgrade-downgrade {
    height: 4vh;
    text-align: center;
    padding: 0;
    color: white;
    font-size: 12pt;
    font-weight: 600;
    border-radius: 0.5rem;
}

#inside-info { 
    margin-left: 10px;
    margin-top: 10px;
    align-items: center;
}

#seperator {
    width: 12%;
    height: 7.5vh;
}

#seperator-settings { 
    width: 22.75%;
    height: 7.5vh;
}

#outline-it {
    padding: 25px;
    margin-right: 50px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
}

#email-text {
    color: var(--main-blue);
    font-weight: 600;
}
