.react-joyride__tooltip {
    background-color: var(--main-blue); /* Background color */
    color: var(--tooltip-text, #fff); /* Fallback to white if not defined */
    font-family: Arial, sans-serif; /* Example font */
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .react-joyride__button--primary {
    background-color: var(--dark-blue-button);
    color: var(--button-text, #fff);
    padding: 8px 16px;
    border-radius: 6px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .react-joyride__button--primary:hover {
    background-color: var(--dark-blue-button-hover);
  }
  
  .react-joyride__button--back {
    color: var(--tooltip-text, #fff);
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .react-joyride__tooltip__arrow {
    display: block;
    color: var(--main-blue);
  }
  