/* row gy-3 justify-content-center*/
.main-row {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem; /* Simulates gy-3 */
}
.row-tos {
    display: flex;
    align-items: center;
    justify-self: center;
}

#show-terms {
    text-decoration: underline;
    cursor: pointer;
}

#login-form {
    display: flex;
    flex-grow: 1;
    margin-bottom: 10vh;
}

#landing-png {
    width: 50%;
    border-radius: 20px;
    align-self: center;
}

#text-center {
    text-align: start;
    margin-top: 12vh;
}

#text-offset {
    margin-top: 0vh;
}

.main-column {
    padding: 0 15px; /* Standard padding for columns */
}

.email {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.termsText {
    background-color: #fefefe;
    padding-left: 10px;
}

.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 50%;
    height: 60%;
    overflow-y: scroll;
}

#termButtons,
#deleteButtons {
    margin: 0;
    padding: 20px;
}

#landing-img {
    height: 100vh;
}

.overlay-tos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }

#modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    margin: 0;
}

.agreeCheck {
    flex: 0 0 auto;
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    border-radius: .25em;
}

#create-account {
    background-color: var(--main-blue);
    border: 0px;
    border-radius: 25px;
    width: 13vw;
    color: white;
    font-weight: 600;
    height: 50px;
}

#accept {
    background-color: var(--main-blue);
    border: 0px;
    border-radius: 25px;
    width: 13vw;
    color: white;
    font-weight: 600;
    height: 50px;
}


#go-back-button {
    padding-top: 15px;
    padding-left: 15px;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

#sign-in-header {
    margin-inline-start: 2px;
    margin-bottom: 10px;
}

#signature {
    margin-top: 15px;
    width: 200px;
    margin-bottom: 15px;
}

#password-reqs {
    margin-left: 2vw;
    color: gray;
}

#password-reqs-list {
    font-size: 11pt;
    color: gray;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

#forgot-password {
    text-decoration: underline;
    color: var(--main-blue-hover);
    border: 0px;
    padding: 0px;
    font-weight: normal;
    margin-top: 0px;
}

#input-text {
    padding: 7px 50px;
    height: 25px;
    width: 20vw;
    border: 3px solid black;
    border-radius: 100px;
}