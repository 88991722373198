#card-box {
    margin: 25px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 50%);
    height: 80%;
    align-items: center;
}

#main-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 90%;
    width: 90%;
}

